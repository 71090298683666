/* General Styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #000;
  color: #ffdd00; /* More vivid yellow */
}

.app {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
}

/* Header Styles */
header {
  background-color: #111;
  padding: 20px 10px;
  color: #ffdd00;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);
}

header .logo {
  max-width: 150px;
  margin-bottom: 10px;
}

header h1 {
  font-size: 28px;
  margin: 10px 0;
}

header p {
  font-size: 16px;
  margin-bottom: 20px;
}

header .explore-button {
  background-color: #ffdd00;
  color: #000;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

header .explore-button:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 10px rgba(255, 221, 0, 0.5);
}

/* Navigation */
nav {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 10px;
}

nav button {
  background-color: #111;
  color: #ffdd00;
  border: 2px solid #ffdd00;
  padding: 12px 24px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
}

nav button:hover {
  background-color: #ffdd00;
  color: #000;
  transform: scale(1.1);
}

nav button.active {
  background-color: #ffdd00;
  color: #000;
}

/* Content */
.content {
  padding: 20px;
}

.content h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.flavor-section h3 {
  font-size: 20px;
  margin-top: 20px;
  text-decoration: underline;
}

ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

ul li {
  margin: 5px 0;
  font-size: 16px;
}

/* Grid for Drinks and Desserts */
.menu-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
}

.menu-item {
  background-color: #111;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(255, 221, 0, 0.3);
  text-align: center;
  color: #ffdd00;
}

.menu-item img {
  width: 100%;
  display: block;
}

.menu-item p {
  margin: 10px 0;
  font-size: 16px;
}

/* Mobile Optimization */
@media (max-width: 600px) {
  header h1 {
    font-size: 22px;
  }

  nav button {
    padding: 8px 16px;
    font-size: 14px;
  }
}
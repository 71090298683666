/* Perks Page Styling */
.content {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

.content h2 {
    margin-bottom: 30px;
    font-size: 34px;
    text-align: center;
    color: #ffdd00;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.perks-section {
    background: linear-gradient(90deg, rgba(255, 221, 0, 0.1), rgba(255, 255, 255, 0.05));
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    margin-bottom: 30px;
    text-align: center;
    color: #ffffff;
}

.perks-section h3 {
    font-size: 26px;
    margin-bottom: 10px;
    color: #ffdd00;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.perks-section p {
    font-size: 18px;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .content h2 {
        font-size: 28px;
    }
    .perks-section h3 {
        font-size: 22px;
    }
}

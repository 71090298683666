.content {
  padding: 20px;
}

.content h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.drinks-section {
  display: flex;
  flex-direction: column;
  gap: 30px; /* Space between categories */
}

/* Individual Drink Categories (Hot Drinks and Smoothies) */
.drinks-category h3 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #ffdd00; /* Vivid yellow for section titles */
}

/* Grid Layout for Drinks */
.menu-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
}

.menu-item {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
}

.menu-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire card */
  display: block;
}

/* Text Overlay */
.text-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  color: #ffdd00; /* Yellow text */
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-name {
  font-size: 16px;
  margin: 0;
}

.item-price {
  font-size: 14px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 5px 10px;
  border-radius: 5px;
}

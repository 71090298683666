footer {
  background: linear-gradient(90deg, #111, #333); /* Subtle gradient */
  color: #ffdd00; /* Vivid yellow for text */
  padding: 30px 20px;
  text-align: center;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.6);
  display: flex;
  flex-wrap: wrap; /* Makes layout responsive */
  justify-content: space-between;
  gap: 20px;
}

/* Content Layout */
.footer-content {
  flex: 1 1 calc(50% - 20px); /* 50% width with gap handling */
  min-width: 250px; /* Prevent too narrow columns */
}

/* Section Titles */
.footer-content h3,
.footer-content h4 {
  margin-bottom: 15px; /* Consistent spacing */
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase; /* Adds sophistication */
  color: #ffffff; /* White for section titles */
  border-bottom: 2px solid #ffdd00; /* Yellow underline */
  display: inline-block;
  padding-bottom: 5px; /* Add space under text */
}

/* Paragraph and Links */
.footer-content p {
  margin: 8px 0; /* Consistent vertical spacing */
  font-size: 14px;
  line-height: 1.5; /* Improve readability */
}

.footer-content a {
  color: #ffdd00;
  text-decoration: none;
  font-weight: bold; /* Make links more prominent */
  transition: color 0.3s ease;
}

.footer-content a:hover {
  text-decoration: underline;
  color: #ffffff; /* Hover effect to white */
}

/* Social Media Icons */
.social-icons {
  margin-top: 20px; /* Consistent spacing */
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between icons */
}

.social-icons a {
  color: #ffdd00;
  font-size: 28px; /* Adjusted for balance */
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icons a:hover {
  color: #ffffff; /* Change to white on hover */
  transform: scale(1.2); /* Subtle hover scaling */
}

/* Responsive Design */
@media (max-width: 768px) {
  footer {
    flex-direction: column; /* Stack content on smaller screens */
    align-items: center;
  }

  .footer-content {
    text-align: center;
    margin-bottom: 20px; /* Add spacing between stacked sections */
  }
}

/* Styling for Hours of Operation */
.footer-content h4 {
  margin-top: 20px;
  font-size: 16px;
  color: #ffffff; /* Consistent white color */
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 2px solid #ffdd00; /* Consistent yellow underline */
  display: inline-block;
  padding-bottom: 5px; /* Add padding under text */
}

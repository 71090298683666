/* Base Styling for Host Page */
.content {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

/* Main Heading */
.content h2 {
  margin-bottom: 30px;
  font-size: 34px;
  text-align: center;
  color: #ffdd00;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

/* PERFECT FOR ANY OCCASION SECTION */
.host-section {
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  margin-bottom: 30px;
  text-align: center;
}

.host-section h3 {
  font-size: 26px;
  margin-bottom: 15px;
  color: #ffdd00;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.host-section ul {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 15px;
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.host-section ul li {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;
  background: rgba(34, 34, 34, 0.9);
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.host-section ul li:hover {
  background: rgba(255, 221, 0, 0.8);
  color: #000;
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(255, 221, 0, 0.6);
}

/* BOOK YOUR EVENT SECTION */
.host-contact {
  background: linear-gradient(90deg, rgba(255, 221, 0, 0.2), rgba(34, 34, 34, 0.8));
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  color: #ffffff;
  margin-bottom: 30px;
}

.host-contact strong {
  font-size: 22px;
  color: #ffdd00;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.host-contact p {
  font-size: 18px;
  line-height: 1.6;
  margin: 15px 0;
}

/* INQUIRE BUTTON */
.contact-button {
  display: inline-block;
  margin-top: 15px;
  padding: 12px 25px;
  background-color: #ffdd00;
  color: #111;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
}

.contact-button:hover {
  background-color: #ffc700;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

/* INQUIRY FORM */
.inquiry-form {
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  margin-top: 30px;
  text-align: center;
}

.inquiry-form input,
.inquiry-form textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid rgba(255, 221, 0, 0.5);
  border-radius: 5px;
  background: rgba(34, 34, 34, 0.8);
  color: #ffffff;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.inquiry-form input::placeholder,
.inquiry-form textarea::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.inquiry-form input:focus,
.inquiry-form textarea:focus {
  background: rgba(255, 221, 0, 0.2);
  border-color: #ffdd00;
}

.inquiry-form textarea {
  height: 120px;
  resize: none;
}

.inquiry-form button {
  background-color: #ffdd00;
  color: #111;
  font-weight: bold;
  text-transform: uppercase;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.inquiry-form button:hover {
  background-color: #ffc700;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

/* Responsive Design */
@media (max-width: 768px) {
  .content h2 {
      font-size: 28px;
  }

  .host-section h3 {
      font-size: 22px;
  }

  .host-section ul {
      grid-template-columns: 1fr 1fr;
  }

  .inquiry-form {
      padding: 15px;
  }
  
  .inquiry-form input, 
  .inquiry-form textarea {
      font-size: 14px;
  }

  .inquiry-form button {
      padding: 10px 18px;
  }
}
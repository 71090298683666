/* Base Styling */
.content {
    padding: 20px;
    max-width: 900px; /* Center content with a defined width */
    margin: 0 auto; /* Center the content horizontally */
    font-family: 'Poppins', sans-serif; /* Modern font for readability */
  }
  
  .content h2 {
    margin-bottom: 30px;
    font-size: 34px; /* Slightly larger for emphasis */
    text-align: center;
    color: #ffdd00; /* Vivid yellow */
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Subtle shadow */
  }
  
  /* Pricing Section */
  .pricing-section {
    margin-bottom: 40px; /* Extra spacing below */
    background: linear-gradient(90deg, rgba(255, 221, 0, 0.1), rgba(255, 255, 255, 0.05)); /* Gradient background */
    padding: 20px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Pronounced shadow */
  }
  
  .pricing-section h3 {
    font-size: 26px;
    margin-bottom: 10px;
    color: #ffffff; /* White for title */
    text-transform: uppercase;
    text-align: center;
  }
  
  .pricing-section p {
    font-size: 18px;
    margin: 15px 0;
    color: #e6e6e6; /* Softer white for description */
    text-align: center;
  }
  
  .pricing-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap */
    justify-content: center; /* Center align items */
    gap: 20px; /* Space between items */
  }
  
  .pricing-list li {
    font-size: 18px;
    color: #ffffff;
    background: linear-gradient(90deg, #111, #222); /* Gradient for pricing cards */
    padding: 15px 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5); /* Enhanced shadow */
    min-width: 200px; /* Ensure consistent sizing */
    flex: 1 1 calc(33.33% - 20px); /* Three columns */
    transition: transform 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
  }
  
  .pricing-list li:hover {
    transform: translateY(-5px); /* Slight lift effect */
    background: linear-gradient(90deg, #222, #333); /* Brighter gradient on hover */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
  }
  
  .pricing-list li span {
    font-weight: bold;
    color: #ffdd00; /* Highlighted yellow for labels */
  }
  
  /* Flavor Sections */
  .flavor-sections {
    display: flex;
    flex-wrap: wrap; /* Wrap items to next row on smaller screens */
    gap: 20px; /* Space between sections */
    justify-content: space-between; /* Space between each list */
    border-top: 1px solid rgba(255, 255, 255, 0.1); /* Separator line between sections */
    padding-top: 20px;
  }
  
  .flavor-section {
    flex: 1 1 calc(45% - 20px); /* Two columns with space between */
    background: linear-gradient(90deg, rgba(255, 221, 0, 0.1), rgba(255, 255, 255, 0.05)); /* Subtle gradient */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Shadow for depth */
  }
  
  .flavor-section h3 {
    font-size: 22px;
    margin-bottom: 15px;
    color: #ffdd00; /* Vivid yellow for section titles */
    text-transform: uppercase;
    text-align: center;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Responsive columns */
    gap: 10px; /* Space between items */
  }
  
  ul li {
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1); /* Subtle background */
    text-align: center;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  ul li:hover {
    transform: scale(1.05); /* Slight scaling effect */
    background-color: rgba(255, 221, 0, 0.3); /* Highlighted background */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .content h2 {
      font-size: 24px;
    }
  
    .pricing-section h3,
    .flavor-section h3 {
      font-size: 20px;
    }
  
    .pricing-list li,
    ul li {
      font-size: 14px;
      flex: 1 1 100%; /* Single column on smaller screens */
    }
  
    .flavor-sections {
      flex-direction: column; /* Stack sections vertically */
    }
  
    .flavor-section {
      flex: 1 1 100%; /* Full width on smaller screens */
    }
  
    ul {
      grid-template-columns: 1fr 1fr; /* Two-column layout for items on mobile */
    }
  }
  
header {
    background: linear-gradient(90deg, #111, #222); /* Subtle gradient */
    padding: 30px 20px; /* Increase padding for a spacious layout */
    color: #ffdd00; /* Vivid yellow for text */
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);
  }
  
  header .logo {
    max-width: 150px;
    margin: 0 auto 15px; /* Center logo and add spacing below */
    display: block; /* Ensure it stays centered */
  }
  
  header h1 {
    font-size: 28px;
    margin: 10px 0;
    text-transform: uppercase; /* Add emphasis to the title */
    letter-spacing: 1.5px; /* Increase letter spacing for a modern look */
  }
  
  header p {
    font-size: 16px;
    margin-bottom: 20px;
    color: #e6e6e6; /* Softer yellow for subtext for better readability */
    line-height: 1.6; /* Improve text readability */
  }
  
  header .explore-button {
    background-color: #ffdd00; /* Vivid yellow button */
    color: #000; /* Black text */
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 20px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.3s ease;
  }
  
  header .explore-button:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 10px rgba(255, 221, 0, 0.5);
    background-color: #f0e130; /* Slightly lighter yellow for hover */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    header {
      padding: 20px 15px;
    }
  
    header .logo {
      max-width: 120px; /* Scale down logo for smaller screens */
    }
  
    header h1 {
      font-size: 24px; /* Reduce font size for smaller screens */
    }
  
    header p {
      font-size: 14px; /* Reduce font size for readability */
    }
  
    header .explore-button {
      font-size: 14px;
      padding: 10px 20px;
    }
  }
  
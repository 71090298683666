.content {
    padding: 20px;
  }
  
  .content h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .menu-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 15px;
  }
  
  .menu-item {
    background-color: #111; /* Dark background for cards */
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(255, 221, 0, 0.3); /* Subtle yellow shadow */
    text-align: center;
    color: #ffdd00; /* Yellow text */
  }
  
  .menu-item img {
    width: 100%;
    display: block;
  }
  
  .menu-item p {
    margin: 10px 0;
    font-size: 16px;
  }
  
nav {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 10px;
  flex-wrap: wrap; /* Allows buttons to wrap on smaller screens */
}

nav button {
  background-color: #111; /* Dark background */
  color: #ffdd00; /* Vivid yellow text */
  border: 2px solid #ffdd00; /* Yellow border */
  padding: 12px 24px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
}

nav button:hover {
  background-color: #ffdd00; /* Highlighted yellow */
  color: #000; /* Black text */
  transform: scale(1.1);
}

nav button.active {
  background-color: #ffdd00; /* Active tab background */
  color: #000; /* Black text */
}

@media (max-width: 600px) {
  nav {
      flex-direction: column;
      align-items: center;
  }

  nav button {
      width: 100%;
      max-width: 300px; /* Limits button width on small screens */
  }
}
